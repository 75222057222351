<template>
  <div>
    <v-autocomplete
      outlined
      :items="payerOptions"
      :label="field.text"
      v-model="value"
      item-value="id"
      item-text="name"
      :rules="validators"
      :disabled="disabled"
      append-icon="mdi-chevron-down"
      dense
    >
      <template #item="{ item }">
        <span style="fontSize: 14px">{{ getPayerLabel(item, 100) }}</span>
      </template>
      <template #selection="{ item }">
        <span style="fontSize: 14px">{{ getPayerLabel(item, 50) }}</span>
      </template>
    </v-autocomplete>
    <Warning
      v-if="disabled"
      message="Zlecenie posiada faktury, nie można zmienić płatnika"
      class="ml-4"
    />
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    payerOptions() {
      return [
        { ...this.data.middleman, type: 'Pośrednik' },
        { ...this.data.client, type: 'Klient' }
      ]
    },
    value: {
      get() {
        return get(this.data, 'payer.id')
      },
      set(value) {
        this.$emit('update', { payerId: value })
      }
    },
    disabled() {
      return this.data?.invoices?.filter(invoice => !invoice?.hidden)?.length > 0
    }
  },
  methods: {
    getPayerLabel(payer, length) {
      const value = `${payer.type} - ${payer.name}`
      return value.length > length ? value.substring(0, length).trim() + '...' : value
    }
  }
}
</script>
